import React from 'react';
import { ComingSoon } from '@/components/empty';
import PrivateProvider from '@/components/providers/PrivateProvider';
import Layout from '@/layouts/HorizontalLayout';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const AgentPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Agent" />
      <PrivateProvider>
        <Layout hideSwitchBtn>
          <ComingSoon />
        </Layout>
      </PrivateProvider>
    </React.Fragment>
  );
};

export default AgentPage;
